import React from "react"
import theme from "theme"
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>
          Our Services | Explore Our Services at Gear & Grind Rentals
        </title>
        <meta
          name={"description"}
          content={"Tailored Adventures, Unforgettable Journeys"}
        />
        <meta
          property={"og:title"}
          content={
            "Our Services | Explore Our Services at Gear & Grind Rentals"
          }
        />
        <meta
          property={"og:description"}
          content={"Tailored Adventures, Unforgettable Journeys"}
        />
        <meta
          property={"og:image"}
          content={"https://rovenabet.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://rovenabet.com/img/favicon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://rovenabet.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://rovenabet.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://rovenabet.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://rovenabet.com/img/favicon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://rovenabet.com/img/favicon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="48px 0 48px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="About-14"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="40%"
          justify-content="flex-start"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          margin="0px 0px 0px 0px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://rovenabet.com/img/4.jpg"
            object-fit="cover"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            sm-min-height="100vw"
            height="100%"
          />
        </Box>
        <Box
          display="flex"
          width="60%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          padding="48px 64px 48px 64px"
          justify-content="center"
          background="--color-lightD1"
          md-padding="36px 40px 48px 40px"
          sm-padding="36px 24px 48px 24px"
        >
          <Text
            as="h1"
            margin="0px 0px 16px 0px"
            color="--dark"
            font="--headline1"
            lg-text-align="center"
            sm-text-align="left"
          >
            What We Offer
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD2"
            font="--base"
            lg-text-align="left"
            sm-text-align="left"
          >
            At Gear & Grind Rentals, we’re dedicated to providing more than just
            a set of wheels. Our services are crafted to enhance your adventure,
            ensuring a seamless experience from start to finish. Dive into the
            array of services designed for the modern explorer, and discover how
            we can make your journey unforgettable.
          </Text>
          <Text
            margin="0px 0px 16px 0px"
            color="--dark"
            font="--headline3"
            lg-text-align="center"
            sm-text-align="left"
          >
            Get in Touch
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD2"
            font="--base"
            lg-text-align="left"
            lg-margin="0px 0px 36px 0px"
            sm-text-align="left"
          >
            Your adventure, customized to perfection. Gear & Grind Rentals –
            where your journey is our priority.
          </Text>
        </Box>
      </Section>
      <Section
        padding="64px 0"
        sm-padding="40px 0"
        font="--base"
        color="--dark"
      >
        <Override slot="SectionContent" align-items="center" />
        <Box
          margin="40px -16px -16px -16px"
          display="grid"
          flex-wrap="wrap"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="32px"
          md-grid-template-columns="repeat(2, 1fr)"
          lg-grid-gap="24px"
          md-grid-gap="16px"
          sm-grid-template-columns="1fr"
          sm-grid-gap="32px"
          width="100%"
        >
          <Box display="flex" flex-direction="column">
            <Box
              height="0"
              margin="0 0 20px 0"
              padding-bottom="100%"
              background="url(https://rovenabet.com/img/1.jpg) 50% 0/cover no-repeat"
              border-radius="8px"
            />
            <Text
              as="h3"
              font="--headline3"
              margin="5px 0 20px 0"
              sm-margin="5px 0 0 0"
            >
              Bike Rentals
            </Text>
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Diverse Fleet: From city cruisers to trail conquerors, select from
              a wide range of motorcycles tailored to your adventure needs.
              <br />
              <br />
              Maintenance & Safety: Every bike is rigorously checked and
              maintained for peak performance and safety.
              <br />
              <br />
              Flexible Booking: Choose from daily, weekly, or custom rental
              periods to suit your journey’s timeline.
            </Text>
          </Box>
          <Box display="flex" flex-direction="column">
            <Box
              height="0"
              margin="0 0 20px 0"
              padding-bottom="100%"
              background="url(https://rovenabet.com/img/6.jpg) 50% 0/cover no-repeat"
              border-radius="8px"
            />
            <Text
              as="h3"
              font="--headline3"
              margin="5px 0 20px 0"
              sm-margin="5px 0 0 0"
            >
              Guided Tours
            </Text>
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Expert-Led Journeys: Embark on guided tours with seasoned riders
              who share your passion and know the roads less traveled.
              <br />
              <br />
              Custom Routes: Personalize your tour based on your interests,
              whether it’s historic landmarks, natural beauty, or hidden gems.
              <br />
              <br />
              Support Vehicles: For group tours, enjoy the peace of mind with
              support vehicles carrying supplies and emergency equipment.
            </Text>
          </Box>
          <Box display="flex" flex-direction="column">
            <Box
              height="0"
              margin="0 0 20px 0"
              padding-bottom="100%"
              background="url(https://rovenabet.com/img/5.jpg) 50% 0/cover no-repeat"
              border-radius="8px"
            />
            <Text
              as="h3"
              font="--headline3"
              margin="5px 0 20px 0"
              sm-margin="5px 0 0 0"
            >
              Riding Gear
            </Text>
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Top-Quality Equipment: Access a selection of helmets, jackets,
              gloves, and GPS units to gear up for your ride.
              <br />
              <br />
              Safety First: All gear meets high safety standards to keep you
              protected on the road.
              <br />
              <br />
              Style & Comfort: Choose from a variety of styles and sizes to find
              the perfect fit for your ride and look good doing it.
            </Text>
          </Box>
          <Box display="flex" flex-direction="column">
            <Box
              height="0"
              margin="0 0 20px 0"
              padding-bottom="100%"
              background="url(https://rovenabet.com/img/8.jpg) 50% 0/cover no-repeat"
              border-radius="8px"
            />
            <Text
              as="h3"
              font="--headline3"
              margin="5px 0 20px 0"
              sm-margin="5px 0 0 0"
            >
              Adventure Planning
            </Text>
            <Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
              Personalized Itineraries: Work with our team to craft an itinerary
              that matches your adventurous spirit.
              <br />
              <br />
              Local Insights: Benefit from our local knowledge to discover
              breathtaking routes and must-see attractions.
              <br />
              <br />
              Accommodation & Dining: Get recommendations for places to stay and
              eat, ensuring a comfortable and enjoyable trip.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
